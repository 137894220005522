import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Drawer,
  Box,
  Grid,
  Typography,
  ButtonBase,
  withStyles,
} from "@material-ui/core";
import { SettingsOutlined } from "@material-ui/icons";
import { KeyboardArrowDown } from "@material-ui/icons";
import { connect } from "react-redux";
import { TENANT_ID } from "../../../config/config";
import LOGO from "../../../static/logo.jpg";
import { topRoutes, bottomRoutes, tenantRoutes } from "../Routes/ProjectRoutes";
import { verifyRouteAccess } from "../../../helpers/accessControlHelper";

const NavDrawerMobile = (props) => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { classes, drawerOpen, onDrawerClose, agent, tenantDetails } = props;
  const openRoute = (route) => {
    history.push(route.to);
    onDrawerClose({});
  };
  const routes = TENANT_ID ? tenantRoutes : topRoutes;
  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={onDrawerClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box px={2} className={classes.container}>
        <Grid container alignItems="center" style={{ margin: "15px 0px" }}>
          <Grid item>
            {TENANT_ID ? (
              <img
                src={
                  Object.keys(tenantDetails).length
                    ? tenantDetails.brandLogo
                    : ""
                }
                alt=""
                className={classes.logo}
              />
            ) : (
              <img src={LOGO} alt="AI Timey" className={classes.logo} />
            )}
          </Grid>
          <Grid>
            <Typography variant="h3" fullWidth>
              {Object.keys(tenantDetails).length
                ? tenantDetails.name
                : "AiSensy"}
            </Typography>
          </Grid>
        </Grid>
        {routes.map((route, index) => {
          if (agent.power < route.power) return null;
          if (!verifyRouteAccess(agent.acl, route.to)) return null;

          let className = "inactive";
          if (location.pathname == route.to) {
            className = "active";
          }
          return (
            <ButtonBase
              key={index}
              className={`${classes.linkButton} ${classes[className]}`}
              onClick={() => openRoute(route)}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes["icon_" + className]}
                  >
                    {route.icon}
                  </Box>
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="body2"
                    align="left"
                    style={{ fontSize: 14 }}
                    className={classes["name_" + className]}
                  >
                    {route.name}
                  </Typography>
                </Grid>
              </Grid>
            </ButtonBase>
          );
        })}
      </Box>
      <Grid
        container
        alignItems="flex-end"
        justify="flex-start"
        className={classes.profileWrapper}
      >
        {bottomRoutes.map((route, index) => {
          return (
            <ButtonBase key={index} onClick={() => openRoute(route)}>
              <Box className={classes.profileIcon}>{route.icon}</Box>
            </ButtonBase>
          );
        })}
      </Grid>
    </Drawer>
  );
};

const styles = (theme) => ({
  container: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    boxSizing: "border-box",
    overflowX: "hidden",
    // minWidth: "300px",
    // flexGrow: 1,
  },
  drawerPaper: {
    width: "calc(100vw - 70px)",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  logo: {
    height: "50px",
    marginRight: "10px",
  },
  link_inactive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "35px",
    width: "35px",
    margin: "auto",
    borderRadius: "50%",
    cursor: "pointer",
    transition: "0.5s",
  },
  link_active: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "35px",
    width: "35px",
    margin: "auto",
    borderRadius: "50%",
    backgroundColor: "white",
    color: "white",
    cursor: "pointer",
    transition: "0.5s",
  },
  // profile_active: {

  // },
  // profile_inactive: {

  // }
  icon_active: {
    color: theme.palette.primary.main,
  },
  icon_inactive: {
    color: "rgb(20,20,20)",
  },
  name_active: {
    color: theme.palette.primary.main,
  },
  name_inactive: {
    color: "rgb(20,20,20)",
  },
  linkButton: {
    margin: "3px 0em",
    width: "100%",
    borderRadius: "5px",
    padding: "10px",
  },
  active: {
    backgroundColor: "#EBF5F3",
  },
  inactive: {
    backgroundColor: "transparent",
  },
  fullWidth: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
  },
  profileWrapper: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    paddingTop: theme.spacing(2) * 1.5,
    paddingBottom: theme.spacing(2) * 1.5,
  },
  profileIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    color: "white",
    width: "40px",
    height: "40px",
    margin: "0px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const connectErrorPage = connect((state) => ({
  tenantDetails: state.tenant.tenant,
}))(NavDrawerMobile);

export default withStyles(styles)(connectErrorPage);
