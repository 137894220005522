import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Chip,
  Snackbar,
  colors,
} from "@material-ui/core";
import { TablePagination } from "@material-ui/core";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import axios from "axios";
import { URL as CLIENT_URL } from "../../config/config";
import { Alert, Skeleton } from "@material-ui/lab";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: "#f5f5f5",
    height: "100vh",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },

  header: {
    marginBottom: theme.spacing(3),
  },

  tabPanel: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    marginTop: theme.spacing(3),
  },

  tableWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: "white",
    // borderRadius: theme.shape.borderRadius,
    height: "calc(100vh - 150px)",
  },

  tableContainer: {
    flex: 1,
    overflow: "auto",
    "& .MuiTableContainer-root": {
      overflow: "auto",
    },
  },

  table: {
    minWidth: 2200,
  },

  tableCell: {
    fontSize: "14px",
    padding: "3px 22px",
    whiteSpace: "nowrap",
    "&.MuiTableCell-head": {
      backgroundColor: "#ebf5f3",
      fontWeight: "bold",
      fontSize: "15px",
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
  },

  pagination: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: "white",
    position: "sticky",
    bottom: 0,
    zIndex: 2,
    width: "100%",
    "& .MuiTablePagination-toolbar": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      "& .MuiTablePagination-spacer": {
        display: "none",
      },
    },
  },

  tableRow: {
    "&:hover": {
      backgroundColor: "#ebf5f3",
    },
  },

  chip: {
    margin: theme.spacing(0.5),
  },

  select: {
    minWidth: 150,
  },

  analyticsContainer: {
    padding: theme.spacing(2),
    width: "100%",
    boxSizing: "border-box",
    overflowY: "auto",
    height: "calc(100vh - 180px)",
  },

  summaryCards: {
    marginBottom: theme.spacing(4),
    display: "flex",
    flexWrap: "nowrap",
  },

  chartsGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing(3),
    marginTop: theme.spacing(3),
  },

  chartContainer: {
    backgroundColor: "white",
    padding: theme.spacing(3),
    // borderRadius: theme.shape(1),
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    width: "100%",
    boxSizing: "border-box",
    height: "400px",
  },

  card: {
    height: "100%",
  },

  dialogContent: {
    minWidth: 500,
  },

  notesSection: {
    marginTop: theme.spacing(2),
  },

  note: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: "#f5f5f5",
    borderRadius: theme.spacing(1),
  },

  flexRow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"];

const CS_REPRESENTATIVES = [
  "Akshay",
  "Samarth Srivastava",
  "Mayank Dixit",
  "Bhavik Lad",
  "Shikha Sharma",
  "Navjot Singh Narang",
  "Shubham Khurana",
  "Bilal Khan",
  "Shivam Thapliyal",
  "Shekhar Vashisth",
  "Jigar Raichada",
  "Abhishek Verma",
  "Nishant Garg",
  "Rohit Singh",
  "Krishna Sharma",
  "Yogita",
  "Shubham Singha",
  "Rishabh khanna",
  "Yatendra Mishra",
  "Abhinav Srivastava",
];

const LEAD_STATUSES = [
  "New",
  "Contacted",
  "In Progress",
  "Setup Complete",
  "With Finance",
  "Closed",
];

const bvbpProcessMapping = {
  0: "Not Started (0)",
  1: "User submitted (1)",
  2: "Forwarded to Meta (2)",
  3: "Limit increased (3)",
  4: "Account not eligible for kyc (4)",
};

function TabPanel({ children, value, index }) {
  return (
    <div hidden={value !== index} style={{ width: "100%" }}>
      {value === index && <Box style={{ width: "100%" }}>{children}</Box>}
    </div>
  );
}

const AccessCodeDashboard = (props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [openLeadDialog, setOpenLeadDialog] = useState(false);
  const [openVoidDialog, setOpenVoidDialog] = useState(false);
  const [voidReason, setVoidReason] = useState("");
  const [newNote, setNewNote] = useState("");
  const [assistantId, setAssistantId] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [isAnalyticsLoading, setIsAnalyticsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const fetchData = async () => {
    setIsTableLoading(true);
    try {
      const response = await axios.get(
        `${CLIENT_URL}/superadmin/get-swipe-logs?page=${page}&limit=${rowsPerPage}`
      );
      setData(response.data.data);
      setTotal(response.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
      handleSnackBar("Error loading customer data", "error");
    } finally {
      setIsTableLoading(false);
    }
  };

  const fetchAnalyticsData = async () => {
    setIsAnalyticsLoading(true);
    try {
      const response = await axios.get(
        `${CLIENT_URL}/superadmin/get-swipe-logs?page=0&limit=9999999`
      );
      setAnalyticsData(response.data.data);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
      handleSnackBar("Error loading analytics data", "error");
    } finally {
      setIsAnalyticsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 1 && analyticsData.length === 0) {
      fetchAnalyticsData();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDailyPurchasesData = () => {
    const purchasesByDate = analyticsData.reduce((acc, item) => {
      const dateString = moment(item.createdAt)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD");
      acc[dateString] = (acc[dateString] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(purchasesByDate)
      .map(([date, count]) => ({
        date: moment(date).format("DD/MM/YYYY"),
        purchases: count,
        sortDate: date,
      }))
      .sort((a, b) => new Date(a.sortDate) - new Date(b.sortDate))
      .map(({ date, purchases }) => ({ date, purchases }));
  };

  const getDailyAmountData = () => {
    const amountByDate = analyticsData.reduce((acc, item) => {
      const dateString = moment(item.createdAt)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD");
      acc[dateString] =
        (acc[dateString] || 0) + (item.body?.transaction?.price?.amount || 0);
      return acc;
    }, {});

    return Object.entries(amountByDate)
      .map(([date, amount]) => ({
        date: moment(date).format("DD/MM/YYYY"),
        amount,
        sortDate: date,
      }))
      .sort((a, b) => new Date(a.sortDate) - new Date(b.sortDate))
      .map(({ date, amount }) => ({ date, amount }));
  };

  const getPaymentMethodsData = () => {
    const methodCounts = analyticsData.reduce((acc, item) => {
      const method = item.body?.paymentDetails?.method || "unknown";
      acc[method] = (acc[method] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(methodCounts).map(([method, value]) => ({
      name: method.toUpperCase(),
      value,
    }));
  };

  const getPlanDistributionData = () => {
    const planCounts = analyticsData.reduce((acc, item) => {
      const planName = item?.body?.product?.name || "Unknown";
      acc[planName] = (acc[planName] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(planCounts).map(([plan, count]) => ({
      plan,
      count,
    }));
  };

  const getWhatsAppStatusData = () => {
    const verified = analyticsData.filter(
      (item) => item.assistantData?.isWhatsappVerified
    ).length;
    const notVerified = analyticsData.length - verified;

    return [
      { name: "Verified", value: verified },
      { name: "Not Verified", value: notVerified },
    ];
  };

  const getCodeActivationData = () => {
    const activated = analyticsData.filter(
      (item) => item.accessTokenUsed
    ).length;
    const pending = analyticsData.length - activated;

    return [
      { name: "Activated", value: activated },
      { name: "Pending", value: pending },
    ];
  };

  const getAdCreditsData = () => {
    const totalCredits = analyticsData.reduce(
      (sum, item) => sum + (item.ctwaAmount || 0),
      0
    );
    const activatedCredits = analyticsData.reduce(
      (sum, item) =>
        sum + (item.ctwaAmount && item.accessTokenUsed ? item.ctwaAmount : 0),
      0
    );

    return [
      { name: "Total Credits", value: totalCredits },
      { name: "Activated Credits", value: activatedCredits },
    ];
  };

  const getSourceDistributionData = () => {
    const sources = analyticsData.reduce((acc, item) => {
      let source = "Unknown";
      if (item.smbTeam) source = "SMB Team";
      else if (item.requestCallBackTeam) source = "Callback Team";
      else if (item.googleAds) source = "Google Ads";
      else if (item.metaAds) source = "Meta Ads";

      acc[source] = (acc[source] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(sources).map(([name, value]) => ({ name, value }));
  };

  const getAdSourceData = () => {
    const adSources = analyticsData.reduce((acc, item) => {
      if (item.googleAds) acc.google = (acc.google || 0) + 1;
      if (item.metaAds) acc.meta = (acc.meta || 0) + 1;
      return acc;
    }, {});

    return [
      { name: "Google Ads", value: adSources.google || 0 },
      { name: "Meta Ads", value: adSources.meta || 0 },
    ];
  };

  const closeSnackbar = () => {
    setAlert(false);
    setAlertMsg("");
    setAlertSeverity("");
  };

  const getSummaryData = () => {
    const totalPurchases = analyticsData.length;
    const totalSales = analyticsData.reduce(
      (sum, item) => sum + (item.body?.transaction?.price?.amount || 0),
      0
    );
    const totalRevenue = totalSales / 1.18;
    const activatedCodes = analyticsData.filter(
      (item) => item.accessTokenUsed
    ).length;
    const whatsappVerified = analyticsData.filter(
      (item) => item.assistantData?.isWhatsappVerified
    ).length;

    return {
      totalPurchases,
      totalSales,
      totalRevenue,
      activatedCodes,
      whatsappVerified,
    };
  };

  const AnalyticsLoadingSkeleton = () => (
    <div className={classes.analyticsContainer}>
      <Grid container spacing={3} className={classes.summaryCards}>
        {[1, 2, 3, 4, 5].map((item) => (
          <Grid item xs={3} key={item}>
            <Card className={classes.card}>
              <CardContent>
                <Skeleton width="60%" height={20} />
                <Skeleton width="40%" height={40} />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <div className={classes.chartsGrid}>
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <div key={item} className={classes.chartContainer}>
            <Skeleton width="40%" height={30} style={{ marginBottom: 20 }} />
            <Skeleton width="100%" height={300} />
          </div>
        ))}
      </div>
    </div>
  );

  const TableLoadingSkeleton = () => (
    <div className={classes.tableWrapper}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {[...Array(props.agent.power > 9 ? 8 : 8)].map((_, index) => (
                <TableCell key={index} className={classes.tableCell}>
                  <Skeleton variant="text" width={200} height={40} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(15)].map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {[...Array(props.agent.power > 9 ? 8 : 8)].map(
                  (_, colIndex) => (
                    <TableCell key={colIndex} className={classes.tableCell}>
                      <Skeleton
                        variant="text"
                        width={colIndex === 0 ? 250 : 200}
                        height={30}
                      />
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={0}
        rowsPerPage={rowsPerPage}
        page={0}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
      />
    </div>
  );

  // Event handlers
  const handleLeadClick = (lead) => {
    setSelectedLead(lead);
    setOpenLeadDialog(true);
  };

  const handleSnackBar = (msg, severity) => {
    setAlert(true);
    setAlertMsg(msg);
    setAlertSeverity(severity);
  };

  const handleAssignCS = async (leadId, csRepresentative) => {
    try {
      await axios.post(`${CLIENT_URL}/superadmin/assign-cs`, {
        leadId,
        csRepresentative,
      });

      handleSnackBar(
        `CS representative assigned to - ${csRepresentative}`,
        "success"
      );

      fetchData();
    } catch (error) {
      console.error("Error assigning CS rep:", error);
      handleSnackBar(`Error assigning CS representative`, "error");
    }
  };

  const handleStatusUpdate = async (leadId, status) => {
    try {
      await axios.post(`${CLIENT_URL}/superadmin/update-lead-status`, {
        leadId,
        status,
      });

      handleSnackBar(`Lead status updated to - ${status}`, "success");

      fetchData();
    } catch (error) {
      console.error("Error updating lead status:", error);
      handleSnackBar(`Error updating lead status`, "error");
    }
  };

  const handleVoidCode = async (leadId, accessToken) => {
    const objectIdRegex = /^[0-9a-fA-F]{24}$/;

    if (
      !voidReason.trim() ||
      !assistantId.trim() ||
      !objectIdRegex.test(assistantId)
    ) {
      handleSnackBar("Please provide a valid assistant ID and reason", "error");
      return;
    }
    try {
      await axios.post(`${CLIENT_URL}/superadmin/void-access-code`, {
        leadId,
        reason: voidReason,
        assistantId: assistantId,
        accessToken,
      });

      handleSnackBar("Access code voided successfully", "success");
      fetchData();
      setOpenVoidDialog(false);
      setVoidReason("");
      setAssistantId("");
    } catch (error) {
      console.error("Error voiding access code:", error);
      handleSnackBar("Error voiding access code", "error");
    }
  };

  const handleAddNote = async () => {
    if (!newNote.trim()) {
      handleSnackBar("Please provide a note", "error");
      return;
    }

    try {
      await axios.post(`${CLIENT_URL}/superadmin/add-note`, {
        leadId: selectedLead._id,
        note: newNote,
      });
      const { data } = await axios.get(
        `${CLIENT_URL}/superadmin/get-lead/${selectedLead._id}`
      );

      handleSnackBar("Note added successfully", "success");
      setSelectedLead(data?.data);
      setNewNote("");
    } catch (error) {
      console.error("Error adding note:", error);
      handleSnackBar("Error adding note", "error");
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.header}>
        Diwali Offer Access Codes
      </Typography>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Customer Data" />
        {props.agent.power > 9 && <Tab label="Analytics" />}
      </Tabs>

      {/* Customer Data Tab */}
      <TabPanel value={tabValue} index={0}>
        {isTableLoading ? (
          <TableLoadingSkeleton />
        ) : (
          <div className={classes.tableWrapper}>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table stickyHeader className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      Plan Name
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Created At
                    </TableCell>
                    {props.agent.power > 9 && (
                      <TableCell className={classes.tableCell}>
                        Access Code
                      </TableCell>
                    )}
                    <TableCell className={classes.tableCell}>Source</TableCell>
                    <TableCell className={classes.tableCell}>
                      Code Status
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      WABA Status
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Assigned person
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      Existing User
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      Project Id
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      Ads Spent
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      Total No. of Ads
                    </TableCell>

                    <TableCell className={classes.tableCell}>Name</TableCell>
                    <TableCell className={classes.tableCell}>Email</TableCell>
                    <TableCell className={classes.tableCell}>Mobile</TableCell>

                    <TableCell className={classes.tableCell}>
                      Amount Paid
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      CTWA Amount
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Flow Price
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Plan Price
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      User Status
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      Payment ID
                    </TableCell>
                    {props.agent.power > 9 && (
                      <TableCell className={classes.tableCell}>
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow
                      key={index}
                      hover
                      onClick={(e) => {
                        if (
                          !e.target.closest("button") &&
                          !e.target.closest(".MuiSelect-root") &&
                          !e.target.closest(".MuiChip-root") &&
                          e.target.closest(".MuiTableCell-root")
                        ) {
                          handleLeadClick(row);
                        }
                      }}
                      className={classes.tableRow}
                    >
                      <TableCell className={classes.tableCell}>
                        {row.flowAddOn
                          ? `${row.planName} + Flow`
                          : row.planName}{" "}
                        🔥
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {new Date(row.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })}
                      </TableCell>

                      {props.agent.power > 9 && (
                        <TableCell className={classes.tableCell}>
                          {row.accessToken}

                          <IconButton
                            size="small"
                            onClick={() =>
                              navigator.clipboard.writeText(row.accessToken)
                            }
                            style={{ marginLeft: 8 }}
                          >
                            <FileCopyOutlinedIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}

                      <TableCell className={classes.tableCell}>
                        {row.smbTeam ? (
                          <span style={{ color: "#3b8132" }}>SMB Team</span>
                        ) : row.requestCallBackTeam ? (
                          <span style={{ color: "#9B51E0" }}>
                            Request Call Back Team
                          </span>
                        ) : row.googleAds ? (
                          <span style={{ color: "#FF9900" }}>Google Ads</span>
                        ) : row.metaAds ? (
                          <span style={{ color: "#4285F4" }}>Meta Ads</span>
                        ) : (
                          <span style={{ color: "gray" }}>Unknown</span>
                        )}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <Chip
                          label={row.accessTokenUsed ? "Activated" : "Pending"}
                          className={classes.chip}
                          style={
                            row.accessTokenUsed
                              ? {
                                  backgroundColor: "rgb(40, 193, 82)",
                                  color: "white",
                                }
                              : { backgroundColor: "#FFBB28", color: "black" }
                          }
                        />
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {row?.assistantData?.isWhatsappVerified &&
                        row?.assistantData?.wabaProgressStep === 9 ? (
                          <span style={{ color: "#06402B" }}>Verified</span>
                        ) : (
                          ""
                        )}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <Select
                          value={row.csRepresentative || "None"}
                          onChange={(e) =>
                            handleAssignCS(row._id, e.target.value)
                          }
                          onClick={(e) => e.stopPropagation()}
                          className={classes.select}
                        >
                          <MenuItem value="None">None</MenuItem>
                          {CS_REPRESENTATIVES.map((rep) => (
                            <MenuItem key={rep} value={rep}>
                              {rep}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {Object.keys(row.existingAgent || {}).length > 0 ? (
                          <span style={{ color: "black" }}>Yes</span>
                        ) : (
                          <span style={{ color: "black" }}>No</span>
                        )}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {row.assistantId}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {row.totalAdSpend || null}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {row.totalAdsData || null}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {row.body?.customer?.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.body?.customer?.email}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.body?.customer?.phone}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        ₹{row.body?.transaction?.price?.amount}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        ₹{row.ctwaAmount || "N/A"}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        ₹{row.flowPrice || "N/A"}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        ₹{row.planAmount || "N/A"}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <Select
                          value={row.leadStatus || "New"}
                          onChange={(e) =>
                            handleStatusUpdate(row._id, e.target.value)
                          }
                          onClick={(e) => e.stopPropagation()}
                          className={classes.select}
                        >
                          {LEAD_STATUSES.map((status) => (
                            <MenuItem key={status} value={status}>
                              {status}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {row.body?.paymentDetails?.gatewayTransactionId}
                      </TableCell>
                      {props.agent.power > 9 && (
                        <TableCell className={classes.tableCell}>
                          {!row.accessTokenUsed && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedLead(row);
                                setOpenVoidDialog(true);
                              }}
                            >
                              <HighlightOffIcon style={{ color: "green" }} />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              className={classes.pagination}
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </TabPanel>

      {/* Analytics Tab */}
      {props.agent.power > 9 && (
        <TabPanel value={tabValue} index={1}>
          {isAnalyticsLoading ? (
            <AnalyticsLoadingSkeleton />
          ) : (
            <div className={classes.analyticsContainer}>
              {/* Summary Cards */}
              <Grid container spacing={3} className={classes.summaryCards}>
                <Grid item xs={3}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Total Purchases
                      </Typography>
                      <Typography variant="h4">
                        {getSummaryData().totalPurchases}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Total Sales
                      </Typography>
                      <Typography variant="h4">
                        ₹{getSummaryData().totalSales.toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Total Revenue
                      </Typography>
                      <Typography variant="h4">
                        ₹{getSummaryData().totalRevenue.toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* New Ad Credits Cards */}
                <Grid item xs={3}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Total Ad Credits Given
                      </Typography>
                      <Typography variant="h4">
                        ₹{getAdCreditsData()[0].value.toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Activated Ad Credits
                      </Typography>
                      <Typography variant="h4">
                        ₹{getAdCreditsData()[1].value.toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Activated Codes
                      </Typography>
                      <Typography variant="h4">
                        {getSummaryData().activatedCodes}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        WhatsApp Verified
                      </Typography>
                      <Typography variant="h4">
                        {getSummaryData().whatsappVerified}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/* Charts Grid */}
              <div className={classes.chartsGrid}>
                {/* Daily Purchase Amount */}
                <div className={classes.chartContainer}>
                  <Typography variant="h6" gutterBottom>
                    Daily Purchase Amount
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={getDailyAmountData()}
                      margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        angle={-45}
                        textAnchor="end"
                        height={60}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="amount"
                        stroke="#8884d8"
                        name="Amount (₹)"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Daily Purchase Count */}
                <div className={classes.chartContainer}>
                  <Typography variant="h6" gutterBottom>
                    Daily Purchases Count
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={getDailyPurchasesData()}
                      margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        angle={-45}
                        textAnchor="end"
                        height={60}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="purchases"
                        stroke="#82ca9d"
                        name="Purchases"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Source Distribution */}
                <div className={classes.chartContainer}>
                  <Typography variant="h6" gutterBottom>
                    Source Distribution
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={getSourceDistributionData()}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ name, percent }) =>
                          `${name} ${(percent * 100).toFixed(0)}%`
                        }
                      >
                        {getSourceDistributionData().map((entry) => (
                          <Cell
                            key={`cell-${entry.name}`}
                            fill={
                              entry.name === "SMB Team"
                                ? "rgb(40, 193, 82)"
                                : entry.name === "Meta Ads"
                                ? "#1E90FF"
                                : entry.name === "Google Ads"
                                ? "#FFD700"
                                : entry.name === "Callback Team"
                                ? "#9B51E0"
                                : "#D3D3D3"
                            }
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                {/* Ad Source Distribution */}
                <div className={classes.chartContainer}>
                  <Typography variant="h6" gutterBottom>
                    Ad Source Distribution
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={getAdSourceData()}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ name, percent }) =>
                          `${name} ${(percent * 100).toFixed(0)}%`
                        }
                      >
                        {getAdSourceData().map((entry) => (
                          <Cell
                            key={`cell-${entry.name}`}
                            fill={
                              entry.name === "Google Ads"
                                ? "#FFD700"
                                : "#1E90FF"
                            }
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                {/* Code Activation Status */}
                <div className={classes.chartContainer}>
                  <Typography variant="h6" gutterBottom>
                    Code Activation Status
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={getCodeActivationData()}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ name, percent }) =>
                          `${name} ${(percent * 100).toFixed(0)}%`
                        }
                      >
                        {getCodeActivationData().map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                {/* WhatsApp Verification Status */}
                <div className={classes.chartContainer}>
                  <Typography variant="h6" gutterBottom>
                    WhatsApp Verification Status
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={getWhatsAppStatusData()}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ name, percent }) =>
                          `${name} ${(percent * 100).toFixed(0)}%`
                        }
                      >
                        {getWhatsAppStatusData().map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                {/* Plan Distribution */}
                <div className={classes.chartContainer}>
                  <Typography variant="h6" gutterBottom>
                    Plan Distribution
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={getPlanDistributionData()}
                      margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="plan"
                        angle={-45}
                        textAnchor="end"
                        height={60}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="count" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}
        </TabPanel>
      )}

      {/* Lead Details Dialog */}
      <Dialog
        open={openLeadDialog}
        onClose={() => setOpenLeadDialog(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedLead && (
          <>
            <DialogTitle>
              <div className={classes.flexRow}>
                <Typography variant="h6">
                  Lead Details - {selectedLead.body?.customer?.name}
                </Typography>
                <IconButton
                  onClick={() => setOpenLeadDialog(false)}
                  style={{ marginLeft: "auto" }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">WABA Status</Typography>
                  <Chip
                    label={
                      selectedLead.assistantData
                        ? selectedLead.assistantData.isWhatsappVerified
                          ? "Verified"
                          : "Not Verified"
                        : "N/A"
                    }
                    color={
                      selectedLead.assistantData?.isWhatsappVerified
                        ? "primary"
                        : "default"
                    }
                    className={classes.chip}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">
                    WABA Progress Step
                  </Typography>
                  <Typography>
                    Step{" "}
                    {selectedLead?.assistantData?.wabaProgressStep || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Campaign Count</Typography>
                  <Typography>{selectedLead.campaignCount || 0}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">BVBP Status</Typography>
                  <Typography>
                    {bvbpProcessMapping[
                      selectedLead?.assistantData?.bvbpProcess
                    ] || "N/A"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Notes Section */}
              <div className={classes.notesSection}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <div className={classes.flexRow}>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    placeholder="Add a note..."
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNote}
                    startIcon={<AddIcon />}
                  >
                    Add
                  </Button>
                </div>
                <div style={{ marginTop: "16px" }}>
                  {selectedLead.notes?.map((note, index) => (
                    <div key={index} className={classes.note}>
                      <Typography variant="body2" color="textSecondary">
                        {new Date(note.timestamp).toLocaleString()}
                      </Typography>
                      <Typography>{note.content}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        By: {note.author}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenLeadDialog(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Void Code Dialog */}
      <Dialog
        open={openVoidDialog}
        onClose={() => setOpenVoidDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ color: "red" }}>
          Mark Access Code as used?
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            label="Assistant ID *"
            placeholder="Enter the assistant ID to void..."
            value={assistantId}
            onChange={(e) => setAssistantId(e.target.value)}
            style={{ marginTop: "16px", marginBottom: "16px" }}
          />
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            label="Reason for voiding *"
            placeholder="Please provide a reason for voiding this access code..."
            value={voidReason}
            onChange={(e) => setVoidReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenVoidDialog(false)} color="default">
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleVoidCode(selectedLead?._id, selectedLead?.accessToken)
            }
            variant="outlined"
            style={{ color: "#FF0000" }}
            // disabled={!voidReason.trim() || !assistantId.trim()}
          >
            Void Code
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={alert}
        autoHideDuration={4000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={closeSnackbar} severity={alertSeverity}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

const connectAccessCodeDashboard = connect((state) => ({
  agent: state.login.user,
  tenantDetails: state.tenant.tenant,
}))(AccessCodeDashboard);
export default withStyles(useStyles)(connectAccessCodeDashboard);
