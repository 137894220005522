import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import axios from "axios";
import {
  withStyles,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  ButtonGroup,
  TextField,
  TextareaAutosize,
  Snackbar,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import {
  AddOutlined,
  NavigateNext,
  NavigateBefore,
  DateRange,
  Clear,
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
} from "@material-ui/icons";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../react-dates-custom.css";
import NumbersTable from "./NumbersTable";
import { TablePagination } from "@material-ui/core";
import { URL } from "../../config/config";
import { Alert } from "@material-ui/lab";
import Switch from "@material-ui/core/Switch";
import NewVirtualNumberInput from "./AddVirtualNumber";
import VirtualNumberVerificationDialog from "./OtpDialog";
class VirtualNumberPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 15,
      totalCount: null,
      skip: 0,
      timeStamp: new Date(),
      search: "",
      numbers: [],
      err: null,
      isLoading: false,
      page: 0,
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focus: null,
        },
        planActivatedOn: {
          startDate: null,
          endDate: null,
          focus: null,
        },
      },
      isNumberSaving: false,
      numbersToSave: "",
      eventCheck: false,
      tabValue: "all",
      otpDialog: false,
      selectedVirtualNumber: "",
    };
  }
  componentDidMount() {
    this.fetchNumbers();
  }
  fetchNumbers = async (isUsed = false) => {
    try {
      this.setState({ isLoading: true, err: null });
      const t = this.state;
      const filter = {};
      if (t.applied.createdAt.startDate) {
        filter["createdAt"] = {
          $gte: t.applied.createdAt.startDate,
          $lte: t.applied.createdAt.endDate,
        };
      }

      const queryObj = {
        skip: t.skip,
        timeStamp: t.timeStamp,
        rowsPerPage: t.rowsPerPage,
        search: t.search,
        activeTab: t.tabValue,
        allFilters: { ...filter },
      };
      if (isUsed) {
        queryObj.allFilters.isUsed = true;
      }
      axios
        .post(URL + "/superadmin/virtual-numbers/numbers", { ...queryObj })
        .then((response) => {
          this.setState({ isLoading: false, err: null });
          const { totalCount, newSkip, numbers } = response.data;
          this.setState({
            skip: newSkip,
            numbers: [...this.state.numbers, ...numbers],
            totalCount,
            isLoading: false,
          });
        })
        .catch((err) => {
          console.error(err);
          this.setState({ err, isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getPageRows = () => {
    const { numbers, page, rowsPerPage } = this.state;
    return numbers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };
  handleChangePage = (event, newPage) => {
    const { numbers, page, rowsPerPage } = this.state;
    const step = newPage - page;
    if (step === 1) {
      const pageRows = numbers.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      );
      if (!pageRows.length) {
        this.fetchNumbers();
      }
    }
    this.setState({ page: newPage });
  };
  setTemplate = (template) => {
    const numbers = [...this.state.numbers];
    const foundIndex = numbers.findIndex((x) => x._id == template._id);
    // console.log({foundIndex})
    // if(foundIndex > -1) {
    numbers[foundIndex] = template;
    this.setState({ numbers });
    // } else {
    // }
    // templates.forEach
  };
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleEnter = (e) => {
    if (e.key == "Enter") {
      this.setState(
        {
          timeStamp: new Date(),
          skip: 0,
          page: 0,
          totalCount: null,
          numbers: [],
          isLoading: true,
          err: null,
        },
        () => {
          this.fetchNumbers();
        }
      );
    }
  };
  // methods for date filter
  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };
  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };
  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };
  applyFilter = () => {
    this.setState(
      {
        timeStamp: new Date(),
        skip: 0,
        page: 0,
        totalCount: null,
        numbers: [],
        isLoading: true,
        err: null,
      },
      () => {
        this.fetchNumbers();
      }
    );
  };

  saveNumbers = () => {
    this.setState({ isNumberSaving: true });
    const payload = { numbers: this.state.numbersToSave };
    axios
      .post(URL + "/superadmin/save-numbers", payload)
      .then((response) => {
        this.setState({
          isNumberSaving: false,
          err: null,
          alert: true,
          alertMsg: "Successfully saved",
          alertSeverity: "success",
          numbersToSave: "",
        });
        this.fetchNumbers();
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          err,
          isNumberSaving: false,
          alert: false,
          alertMsg: "Failed adding number",
          alertSeverity: "error",
        });
      });
  };

  refreshList = () => {
    this.setState(
      { rowsPerPage: 15, totalCount: null, skip: 0, numbers: [] },
      () => {
        this.fetchNumbers();
      }
    );
  };

  setSnackbar = (alertMsg, alertSeverity) => {
    this.setState({ alert: true, alertMsg, alertSeverity });
  };

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };
  changeToUnused = (e) => {
    console.log(e);
    const eventToLog = e.target.checked;
    this.setState(
      {
        eventCheck: e.target.checked,
        skip: 0,
        numbers: [],
      },
      () => {
        if (eventToLog) {
          this.fetchNumbers(true);
        } else {
          this.fetchNumbers();
        }
      }
    );
  };

  handleTabs = (event, newValue) => {
    this.setState(
      {
        tabValue: newValue,
        rowsPerPage: 15,
        totalCount: null,
        skip: 0,
        numbers: [],
      },
      () => {
        this.fetchNumbers();
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Grid
          container
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
          spacing={2}
        >
          <Grid item>
            <Box pl={{ xs: 2, md: 3 }}>
              <Typography variant="h3">Virtual Numbers</Typography>
            </Box>
          </Grid>

          <NewVirtualNumberInput
            setSnackbar={this.setSnackbar}
            updateList={this.refreshList}
          />

          <Grid item>
            <TextField
              name="search"
              placeholder="Enter the number or person assigned to..."
              className={classes.textField}
              onChange={this.handleInput}
              onKeyPress={this.handleEnter}
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={this.applyFilter}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        <Box my={3} />
        <Box my={3} />

        {/* Layout */}
        <Grid container justify="center">
          <Grid item xs={12} md={11} lg={10}>
            {/* <NewVirtualNumberInput
              setSnackbar={this.setSnackbar}
              updateList={this.refreshList}
              newUI={true}
            /> */}

            <Tabs
              value={this.state.tabValue}
              onChange={this.handleTabs}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab value="all" label={"All"} />
              <Tab value="reserved" label={"Reserved Numbers"} />
              <Tab value="verificationPending" label={"Verification Pending"} />
              <Tab value="readyToUse" label={"Ready for use"} />
              <Tab value="tempAllocated" label={"Temporary Allocated"} />
              <Tab value="alloted" label={"Alloted"} />
            </Tabs>
            {/* First section */}
            {/* Scrollable (x & y) table container */}
            <div className={classes.tableContainer}>
              <NumbersTable
                numbers={this.getPageRows()}
                isLoading={this.state.isLoading}
                activeTab={this.state.tabValue}
                onClick={(selectedVirtualNumber) =>
                  this.setState({ selectedVirtualNumber, otpDialog: true })
                }
              />
            </div>
          </Grid>
        </Grid>
        {/* Table footer fixed at bottom */}
        <div className={classes.fixedBottomContainer}>
          <TablePagination
            component="div"
            count={this.state.totalCount || 0}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            rowsPerPageOptions={[25]}
          />
        </div>

        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
        {this.state.otpDialog && (
          <VirtualNumberVerificationDialog
            open={this.state.otpDialog}
            onClose={() => this.setState({ otpDialog: false })}
            virtualNumber={this.state.selectedVirtualNumber}
            setSnackbar={this.setSnackbar}
            updateList={this.refreshList}
          />
        )}
      </div>
    );
  }
}

// Navigattion icons used in date fliters
function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <div className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </div>
  );
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box mt={2} mb={2} className={classes.filterTypeContainer}>
      <Grid container justify="space-between" alignItems="center">
        {A.startDate && A.endDate ? (
          <Grid item>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item>
          <Typography variant="body1">{filterName}</Typography>
        </Grid>
        <Grid item xs>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            navPrev={
              <NavigationWrapper classes={classes}>
                <NavigateBefore />
              </NavigationWrapper>
            }
            navNext={
              <NavigationWrapper classes={classes}>
                <NavigateNext />
              </NavigationWrapper>
            }
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
          />
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  numberUpdateContiner: {
    zIndex: 100,
    top: 0,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
  fixedBottomContainer: {
    position: "fixed",
    height: "60px",
    borderTop: "1px solid lightgrey",
    background: "white",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
  tableContainer: {
    top: "60px",
    height: "calc(100vh - 70px - 60px - 40px)",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      // position top + bottombar height + bottom padding + navbar
      height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  textField: {
    width: 300,
  },
});

export default withStyles(styles)(VirtualNumberPage);
