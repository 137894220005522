import axios from "axios";
import { URL } from "./config";
import store from "../store/store";

export const configureAxios = () => {
  // Add header for request source set to "superadmin"
  axios.defaults.headers.common["x-request-source"] = "superadmin";

  axios.defaults.withCredentials = true;

  // axios request intereptor to check logged in status
  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  let sharedTokenRefreshPromise = null;
  // axios response intereptor to reissue token or logout if session invalidated
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        try {
          if (error.response.data?.message === "Token expired.") {
            // Attempt to refresh token
            if (!sharedTokenRefreshPromise) {
              sharedTokenRefreshPromise = axios.post(
                URL + "/auth/get-new-agent-token",
                {}
              );
            }

            // Retry the failed request
            await sharedTokenRefreshPromise;
            sharedTokenRefreshPromise = null;
            return axios(error.config);
          }

          if (error.response?.data?.logout) {
            throw Error("Session invalidated");
          }
        } catch (err) {
          sharedTokenRefreshPromise = null;
          await handleSessionInvalidation();
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );
};

const handleSessionInvalidation = async () => {
  try {
    const session = localStorage.getItem("session");
    localStorage.removeItem("session");
    if (session) {
      const { _id } = JSON.parse(atob(session) || "{}");
      await axios.post(URL + "/auth/logOut", { _id }).catch((err) => {
        console.log("Logout failed", err);
      });
    }
    store.dispatch({ type: "Login/SIGN_OUT_SUCCESS" });
    window.location.href = "/login";
  } catch (error) {
    console.error("Error in handleSessionInvalidation", error);
  }
};
