import React, { useEffect, useState } from "react";
import { URL } from "../../config/config";
import {
  Box,
  Button,
  DialogActions,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";

const CheckStatus = (props) => {
  const {
    businessId,
    dailyTemplateLimit,
    syncMessagingLimit,
    alertHandler,
    displayNameStatus,
  } = props;

  const [checkStatusObject, setCheckStatusObject] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const payload = {
      end_business_id: businessId,
    };
    if (businessId) {
      setLoading(true);
      axios
        .get(`${URL}/superadmin/get-bvbp-status`, {
          params: payload,
        })
        .then((response) => {
          const lastAttempt = response.data?.data?.length;
          if (lastAttempt === 0) {
            setCheckStatusObject((prevState) => ({
              ...prevState,
              verificationStatus: "Not submitted Yet",
            }));
          } else {
            const latestStatus = response.data.data.reduce((latest, record) => {
              return new Date(latest.update_time) > new Date(record.update_time)
                ? latest
                : record;
            });
            setCheckStatusObject((prevState) => ({
              ...prevState,
              verificationStatus: latestStatus.verification_status,
              rejectionReasons: latestStatus.rejection_reasons,
              bvbpCount: lastAttempt,
              bvbpApplicationResponse: response.data.data,
            }));
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error", error);
          setLoading(false);
          alertHandler(true, "Error fetching status", "error");
        });
    } else {
      alertHandler(true, "Business Id missing", "error");
    }
  }, []);

  return (
    <div style={{ minHeight: "600px", minWidth: "700px" }}>
      {" "}
      {isLoading ? (
        <Skeleton
          variant="rect"
          style={{ minHeight: "600px", minWidth: "700px" }}
        />
      ) : (
        <Box>
          <Grid container>
            {checkStatusObject?.bvbpCount != undefined && (
              <Grid
                md={12}
                xs={12}
                style={{
                  margin: "5px 0px 9px 0px",
                }}
              >
                <Grid container md={12}>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: "500", paddingLeft: "16px" }}
                  >
                    Bvbp apply count : {`${checkStatusObject?.bvbpCount || 0}`}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {/* 
            {checkStatusObject.verificationStatus && (
              <Grid container md={12}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: "500", paddingLeft: "16px" }}
                >
                  Bvbp apply status : {checkStatusObject?.verificationStatus}
                </Typography>
              </Grid>
            )} */}

            {displayNameStatus && (
              <Grid
                container
                md={12}
                style={{
                  margin: "7px 0px 0px 0px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: "500", paddingLeft: "16px" }}
                >
                  WABA Display Name Status : {displayNameStatus}
                </Typography>
              </Grid>
            )}

            <Grid container style={{ margin: "0px 16px 0px 16px" }}>
              <Grid
                item
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">
                  Messaging Tier Limit - {dailyTemplateLimit}
                </Typography>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={syncMessagingLimit}
                >
                  Sync
                </Button>
              </Grid>
            </Grid>
            {checkStatusObject?.bvbpApplicationResponse?.length > 0 &&
              checkStatusObject?.bvbpApplicationResponse?.map((data, i) => (
                <Grid
                  container
                  xl={12}
                  md={12}
                  sm={12}
                  style={{
                    width: "100%",
                    border: "1px solid #b0b0b0",
                    margin: "10px 0px",
                    padding: "16px",
                    background: "#e9e9e9",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: "600", marginBottom: "10px" }}
                    >
                      {`Attempt ${
                        checkStatusObject?.bvbpApplicationResponse.length - i
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <pre
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      {JSON.stringify(data, null, 2)}
                    </pre>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default CheckStatus;
