import React, { useState } from "react";
import { URL } from "../../config/config";
import {
  makeStyles,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import { ArrowDropDown } from "@material-ui/icons";

function NewVirtualNumberInput({ updateList, setSnackbar, newUI }) {
  const classes = useStyles();
  const [number, setNumber] = useState("");
  const [assignedTo, setAssignedTo] = useState("MyOperator");

  const handleInput = (e) => {
    setNumber(e.target.value);
  };

  const handleSubmit = async () => {
    if (number.trim()) {
      try {
        await addNewNumber(number, assignedTo);
        setNumber("");
      } catch (error) {
        console.log("Handle Add number", error);
      }
    } else {
      setSnackbar("Enter a valid number", "error");
    }
  };

  const handleAssignedToChange = (event, newValue) => {
    setAssignedTo(newValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const addNewNumber = async (phoneNumber, assignedTo) => {
    try {
      await axios.post(`${URL}/superadmin/virtual-numbers/number`, {
        phoneNumber,
        assignedTo,
      });
      setSnackbar("Number added succesfully", "success");
      updateList();
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        "Something went wrong";
      setSnackbar(message, "error");
    }
  };

  return newUI ? (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <Typography className={classes.title}>New Virtual Number</Typography>
        <TextField
          placeholder="Enter the number to be added here..."
          value={number}
          onChange={handleInput}
          onKeyPress={handleKeyPress}
          className={classes.input}
        />
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Add Number
        </Button>
      </Box>
    </Box>
  ) : (
    <>
      <Grid item>
        <TextField
          name="search"
          placeholder="Enter the number to be added here..."
          // className={classes.textField}
          className={classes.input}
          onChange={handleInput}
          onKeyPress={handleKeyPress}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id="receiver-autocomplete"
          options={[
            "MyOperator",
            "Yash",
            "Pratistha",
            "Indrajit",
            "Saurabh",
            "Chetna",
            "Nidhi",
            "Vaibhav",
            "Devansh",
            "Shekhar",
            "Navjot",
            "Preeti",
          ]}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Assigned To"
              className={classes.otpInput}
            />
          )}
          onChange={handleAssignedToChange}
          freeSolo
          popupIcon={<ArrowDropDown />}
          forcePopupIcon={true}
        />
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          style={{ marginRight: 32 }}
          onClick={handleSubmit}
        >
          Add Number
        </Button>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: 16,
    marginTop: 16,
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "14px 20px",
    backgroundColor: "#fff",
    borderRadius: "2px",
    transition: "all 0.2s ease",
    border: "1px solid #e0e0e0",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
  },
  title: {
    fontSize: "16px",
    color: "#333",
    fontWeight: 600,
    height: "40px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.3px",
  },
  input: {
    width: "280px",
    "& .MuiOutlinedInput-root": {
      height: "40px",
      backgroundColor: "#f5f5f5",
      transition: "all 0.2s ease",
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#bdbdbd",
      },
      "&.Mui-focused": {
        "& fieldset": {
          borderColor: "#9e9e9e",
          borderWidth: 1,
        },
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px",
      fontSize: "14px",
      height: "24px",
      color: "#424242",
    },
    "& input::placeholder": {
      color: "#757575",
      opacity: 1,
    },
  },
  otpInput: {
    width: "165px",
    "& .MuiOutlinedInput-root": {
      height: "40px",
      backgroundColor: "#f5f5f5",
      transition: "all 0.2s ease",
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#bdbdbd",
      },
      "&.Mui-focused": {
        "& fieldset": {
          borderColor: "#9e9e9e",
          borderWidth: 1,
        },
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px",
      fontSize: "14px",
      height: "24px",
      color: "#424242",
    },
    "& input::placeholder": {
      color: "#757575",
      opacity: 1,
    },
  },
  button: {
    height: "40px",
    minWidth: "90px",
    textTransform: "none",
    padding: "0 20px",
    borderRadius: "6px",
    backgroundColor: "#2e7d32",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 500,
    boxShadow: "none",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "#1b5e20",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#1b5e20",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e0e0e0",
      color: "#757575",
    },
  },
}));

export default NewVirtualNumberInput;
