import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { URL } from "../../config/config";
import axios from "axios";
import { ErrorOutline, CheckCircleOutline } from "@material-ui/icons";

const VirtualNumberVerificationDialog = ({
  open,
  onClose,
  virtualNumber,
  setSnackbar,
  updateList
}) => {
  const classes = useStyles();
  const [step, setStep] = useState("initial");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setStep("initial");
    setOtp("");
    setError("");
    setSuccess(false);
    setLoading(false);
    onClose();
  };

  const handleGenerateOTP = async () => {
    try {
      setLoading(true);
      await axios.post(`${URL}/superadmin/virtual-numbers/trigger-otp`, {
        phoneNumber: virtualNumber,
      });
      setSnackbar("Verification code sent successfully", "success");
      setStep("verify");
      setError("");
    } catch (err) {
      setError("Failed to send verification code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async () => {
    if (!otp || otp.length < 6) {
      setError("Please enter a valid 6-digit code");
      return;
    }

    try {
      setLoading(true);
      await axios.post(`${URL}/superadmin/virtual-numbers/verify-otp`, {
        phoneNumber: virtualNumber,
        otp,
      });
      setSnackbar("Virtual number verified successfully", "success");
      setSuccess(true);
      setError("");
      updateList?.(); // Optional update list callback
      setTimeout(() => {
        handleClose();
      }, 1500);
    } catch (err) {
      setError(err.response?.data?.message || "Invalid verification code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && otp.length === 6 && step === 'verify') {
      handleVerifyOTP();
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      className={classes.dialog}
      aria-labelledby="verification-dialog-title"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.step}>
          Step {step === "initial" ? "1/2" : "2/2"}
        </Typography>
        {step === "initial" ? "Verify Virtual Number" : "Enter Verification Code"}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {step === "initial" && (
          <Box>
            <Typography variant="body1" className={classes.virtualNumber}>
              {virtualNumber}
            </Typography>
            <DialogContentText className={classes.description}>
              To verify this virtual number, we'll send a verification code.
              Click Continue to receive the code.
            </DialogContentText>
          </Box>
        )}

        {step === "verify" && (
          <Box>
            <DialogContentText className={classes.description}>
              We've sent a verification code to your registered contact method.
              Please enter the code below.
            </DialogContentText>
            <TextField
              className={classes.otpField}
              placeholder="Enter 6-digit code"
              variant="outlined"
              fullWidth
              value={otp}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, '');
                if (value.length <= 6) setOtp(value);
              }}
              onKeyPress={handleKeyPress}
              inputProps={{ 
                maxLength: 6,
                pattern: '[0-9]*',
                inputMode: 'numeric'
              }}
              disabled={loading || success}
              autoFocus
            />
          </Box>
        )}

        {error && (
          <Typography variant="body2" className={classes.error}>
            <ErrorOutline className={classes.statusIcon} />
            {error}
          </Typography>
        )}

        {success && (
          <Typography variant="body2" className={classes.success}>
            <CheckCircleOutline className={classes.statusIcon} />
            Virtual number verified successfully!
          </Typography>
        )}
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>

        {step === "initial" ? (
          <Button
            onClick={handleGenerateOTP}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {loading ? (
              <div className={classes.loader}>
                <CircularProgress size={20} />
                <span>Sending code...</span>
              </div>
            ) : (
              "Continue"
            )}
          </Button>
        ) : (
          <Button
            onClick={handleVerifyOTP}
            color="primary"
            variant="contained"
            disabled={!otp || otp.length < 6 || loading || success}
          >
            {loading ? (
              <div className={classes.loader}>
                <CircularProgress size={20} />
                <span>Verifying...</span>
              </div>
            ) : (
              "Verify Code"
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 12,
      padding: theme.spacing(2),
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.2)'
    }
  },
  dialogTitle: {
    '& .MuiTypography-h6': {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: theme.palette.primary.main
    },
    padding: theme.spacing(2, 3)
  },
  dialogContent: {
    padding: theme.spacing(0, 3, 2),
    minWidth: 400,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto'
    }
  },
  otpField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.5, 2),
      fontSize: '1.1rem',
      letterSpacing: 4,
      textAlign: 'center'
    }
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.error.light + '20',
    borderRadius: 8
  },
  success: {
    color: theme.palette.success.main,
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.success.light + '20',
    borderRadius: 8
  },
  virtualNumber: {
    fontWeight: 600,
    fontSize: '1.2rem',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[200]}`
  },
  description: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2)
  },
  actions: {
    padding: theme.spacing(2, 3),
    '& .MuiButton-root': {
      borderRadius: 8,
      padding: theme.spacing(1, 3),
      textTransform: 'none',
      fontSize: '0.95rem'
    },
    '& .MuiButton-contained': {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: '0 3px 6px rgba(0,0,0,0.1)'
      }
    }
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  step: {
    color: theme.palette.grey[500],
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1)
  },
  statusIcon: {
    fontSize: 20
  }
}));

export default VirtualNumberVerificationDialog;