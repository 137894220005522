import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Checkbox,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import clsx from "clsx";
import { Send } from "@material-ui/icons";

const fields = [
  { name: "Virtual Number", key: "virtualNumber" },
  { name: "Source", key: "source" },
  {
    name: "Is Pre-Verified",
    key: "isPreVerified",
  },
  {
    name: "Pre-Verified At",
    key: "preVerifiedAt",
  },
  {
    name: "Assigned To",
    key: "assignedTo",
  },
  {
    name: "Project Name",
    key: "assistantName",
  },
  {
    name: "Business Name",
    key: "businessName",
  },
  {
    name: "Business Email",
    key: "businessEmail",
  },
  {
    name: "Business Contact",
    key: "businessContact",
  },
  {
    name: "Created At",
    key: "createdAt",
  },
];

const quickFilters = [
  "Last active - 24 hr window",
  "Created - This week",
  "Created - This month",
];

class NumbersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedTemplate: null,
    };
  }

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  openTemplate = (e) => {
    const numbers = [...this.props.numbers];
    const foundIndex = numbers.findIndex((x) => x._id == e._id);
    this.setState({ openDialog: true, selectedTemplate: foundIndex });
  };

  render() {
    const { classes, numbers, isLoading, onClick, activeTab } = this.props;
    return (
      <div className={classes.root}>
        <table>
          <tbody>
            <tr className={`${classes.row} ${classes.topbar}`}>
              {fields.map((field) => (
                <th
                  key={field.key}
                  className={clsx(classes.column, classes.dataColumn)}
                >
                  <Typography variant="h5" color="primary" align="center">
                    {field.name.toUpperCase()}
                  </Typography>
                </th>
              ))}
            </tr>
            {isLoading ? (
              <Box my={4} display="flex" justifyContent="center">
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <React.Fragment>
                {numbers.map((e, index) => (
                  <tr
                    key={e._id || index}
                    className={clsx(classes.row, classes.dataRow, {
                      [classes.alterRow]: index % 2 == 0,
                      [classes.stayToLeft]: e.key == "assistantName",
                    })}
                  >
                    {fields.map((field) => (
                      <th
                        key={field.key}
                        className={clsx(classes.column, classes.dataColumn)}
                        onClick={() => {
                          if (activeTab !== "verified") {
                            const selectedNumber = e.virtualNumber;
                            onClick(selectedNumber);
                          }
                        }}
                      >
                        {columnType(classes, e, field)}
                      </th>
                    ))}
                    {activeTab !== "verified" && (
                      <div className={classes.rowOverlay}>
                        <Button
                          variant="outlined"
                          size="small"
                          className={classes.triggerButton}
                          onClick={(evt) => {
                            evt.stopPropagation();
                            onClick?.(e?.virtualNumber);
                          }}
                        >
                          <Send className={classes.buttonIcon} />
                          <span className={classes.buttonText}>
                            Trigger OTP
                          </span>
                        </Button>
                      </div>
                    )}
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

function columnType(classes, columnData, fieldName) {
  const key = fieldName.key;
  switch (key) {
    case "remainingCredit":
      return (
        <Typography variant="body1" align="center">
          {columnData[key] / 100}
        </Typography>
      );
    case "businessName":
      return (
        <Typography variant="body1" align="center">
          {columnData?.allotedUserDetails?.[0]?.[key] || "NA"}
        </Typography>
      );
    case "businessEmail":
      return (
        <Typography variant="body1" align="center">
          {columnData?.allotedUserDetails?.[0]?.[key] || "NA"}
        </Typography>
      );
    case "businessContact":
      return (
        <Typography variant="body1" align="center">
          {columnData?.allotedUserDetails?.[0]?.[key] || "NA"}
        </Typography>
      );
    case "assignedTo":
      return (
        <Typography variant="body1" align="center">
          {columnData[key] || "NA"}
        </Typography>
      );
    case "assistantName":
      return (
        <Typography variant="body1" align="center">
          {columnData?.allotedUserDetails?.[0]?.[key] || "NA"}
        </Typography>
      );
    case "preVerifiedAt":
      return (
        <Typography variant="body1" align="center">
          {columnData[key] ? getFormattedDate(columnData[key]) : "NA"}
        </Typography>
      );
    case "createdAt":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData[key])}
        </Typography>
      );
    case "planActivatedOn":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData[key])}
        </Typography>
      );
    default:
      return (
        <Typography variant="body1" align="center">
          {`${columnData[key] != null ? columnData[key] : "NA"}`}
        </Typography>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}

const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    boxSizing: "border-box",
    position: "relative",
    border: "1px solid lightgrey",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      // height: "calc(100vh - 60px)",
    },
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      background: "white",
    },
  },
  row: {
    background: "white",
    display: "flex",
  },
  alterRow: {
    background: "rgb(246,246,246)",
  },
  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    position: "relative",
    "&:hover": {
      "& $rowOverlay": {
        opacity: 1,
        visibility: "visible",
      },
      "& th": {
        opacity: 0.4,
        transition: "opacity 0.2s ease",
      },
    },
  },
  topbar: {
    background: "rgb(252,252,252)",
    position: "sticky",
    top: "0",
    zIndex: "100",
  },
  column: {
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
  dataColumn: {
    width: "200px",
  },
  stayToLeft: {
    position: "sticky",
    left: 0,
    top: 0,
  },
  checkboxColumn: {
    width: "60px",
  },
  rowOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "80vw",
    opacity: 0,
    visibility: "hidden",
    transition: "opacity 0.2s ease, visibility 0.2s ease",
    zIndex: 1,
    background: "rgba(0, 0, 0, 0.15)",
  },
  triggerButton: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.5, 2),
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.grey[600],
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: "1rem",
  },
  buttonText: {
    fontSize: "0.875rem",
  },
});

export default withStyles(styles)(NumbersTable);
