import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    padding: "2rem 1rem",
    backgroundColor: "#EBF5F3",
    borderRadius: "12px",
  },
  textField: {
    backgroundColor: "white",
    border: "1px solid black",
  },
  textFieldDisabled: {
    backgroundColor: "white",
  },
});

/**
 * @typedef {import("../../types").Partner} Partner
 */

/**
 * @typedef {Object} PartnerEmbeddedInputProps
 * @property {Partner} partner
 * @property {Agent} agent
 * @property {(key: string, value: string) => Promise<void>} updateAttribute
 */

/**
 * @param {PartnerEmbeddedInputProps} props
 */
function PartnerEmbeddedInput({ partner, agent, updateAttribute }) {
  const classes = useStyles();
  const [
    {
      appId,
      appSecret,
      solutionId,
      businessManagerId,
      systemUserAccessToken,
      esConfigId,
    },
    setState,
  ] = useState({
    appId: partner?.appId || "",
    appSecret: partner?.appSecret || "",
    solutionId: partner?.solutionId || "",
    businessManagerId: partner?.businessManagerId || "",
    systemUserAccessToken: partner?.systemUserAccessToken || "",
    esConfigId: partner?.esConfigId || "",
  });

  const setAttribute = (key, value) => {
    setState((state) => ({ ...state, [key]: value }));
  };

  const handleAttriuteUpdate = async (key, value) => {
    await updateAttribute(key, value);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner App ID - Enter the App ID to update
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Partner App ID - {partner.appId}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            value={appId}
            disabled={agent.power <= 10}
            onChange={(e) => setAttribute("appId", e.target.value)}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAttriuteUpdate("appId", appId)}
            >
              Update App ID
            </Button>
          )}
        </Grid>
      </Grid>

      {/** Partner App Secret */}
      <Box my={6}>{/* <Divider /> */}</Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner App Secret - Enter the App Secret to update
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current App Secret - {partner.appSecret}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            value={appSecret}
            disabled={agent.power <= 10}
            onChange={(e) => setAttribute("appSecret", e.target.value)}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAttriuteUpdate("appSecret", appSecret)}
            >
              Update App Secret
            </Button>
          )}
        </Grid>
      </Grid>

      {/** Partner ES Config ID */}
      <Box my={6}>{/* <Divider /> */}</Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner ES Config ID - Enter the ES Config ID to update
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current ES Config ID- {partner.esConfigId}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            value={esConfigId}
            disabled={agent.power <= 10}
            onChange={(e) => setAttribute("esConfigId", e.target.value)}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAttriuteUpdate("esConfigId", esConfigId)}
            >
              Update ES Config ID
            </Button>
          )}
        </Grid>
      </Grid>

      {/** Partner Solution ID */}
      <Box my={6}>{/* <Divider /> */}</Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner Solution ID - Enter the Solution ID to update
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Partner Solution ID - {partner.solutionId}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            value={solutionId}
            disabled={agent.power <= 10}
            onChange={(e) => setAttribute("solutionId", e.target.value)}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAttriuteUpdate("solutionId", solutionId)}
            >
              Update Solution ID
            </Button>
          )}
        </Grid>
      </Grid>

      {/** Partner FB Business Manager ID */}
      <Box my={6}>{/* <Divider /> */}</Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner FB Business Manger ID - Enter the FB Business Manager ID to
        update
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Partner FB Business Manager ID - {partner.businessManagerId}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            value={businessManagerId}
            disabled={agent.power <= 10}
            onChange={(e) => setAttribute("businessManagerId", e.target.value)}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleAttriuteUpdate("businessManagerId", businessManagerId)
              }
            >
              Update FB Business Manager ID
            </Button>
          )}
        </Grid>
      </Grid>
      {/** Partner System User Token ID */}
      <Box my={6}>{/* <Divider /> */}</Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner System User Token - Enter the System User Token to update
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Partner System User Token -{" "}
        {partner.systemUserAccessToken ? "SET" : "NOT SET"}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            value={
              systemUserAccessToken === true
                ? "********************"
                : systemUserAccessToken
            }
            disabled={agent.power <= 10}
            onChange={(e) =>
              setAttribute("systemUserAccessToken", e.target.value)
            }
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleAttriuteUpdate(
                  "systemUserAccessToken",
                  systemUserAccessToken
                )
              }
            >
              Update Token
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
const connectedPartnerEmbeddedInput = connect((state) => ({
  agent: state.login.user,
}))(PartnerEmbeddedInput);

export default connectedPartnerEmbeddedInput;
